.job {
  color: rgb(7, 7, 8);
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  max-width: 750px;
  padding: 2rem 1rem;
  padding: 30px 20px;
  margin: 20px auto;
  flex-wrap: wrap;
  border-bottom: 1px solid #d9daea;
}

.job:hover:after {
  content: "";
  position: absolute;
  top: 40px;
  left: -20px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid teal;
}
.job:hover h3 {
  display: inline;
  background-color: rgba(215, 248, 250, 0.463);
}

.job-description {
  max-width: 450px;
}
.job-description h3 {
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 10px;
  /* Don't add MAX-WIDTH to this line. It will break the layout. MAX-WIDTH can be set in parent element. Here it breaks inline model.  */
}
.job-description img {
  position: relative;
  top: 12px;
  max-height: 44px;
}
.job-technologies {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
}
.job-technology {
  background-color: #d7e0eb;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.job-technology:hover {
  background-color: #b9cce3;
}

.job-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: space-around;
  margin-top: 10px;
  justify-content: space-between;
}

.job-action a {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 800;
  color: var(--green);
  text-decoration: none;
}


.job-action a:hover {
  text-decoration: underline;
}

.remote {
  background-color: #d7ebe2;
  color:var(--green);
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .job-actions {
    width: 100%;
    margin-top: 0px;
    align-items: center;
    flex-direction: row-reverse;
  }
  .remote{
    margin-bottom: 0;
  }
}
.load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 750px;
  margin: 20px auto;
  flex-wrap: wrap;
}

#jobs {
  margin-top: 70px;
  margin-bottom: 100px;
}
