/* ------------------------------------
    $FOOTER
------------------------------------ */


  footer {
    display: block;
  }

  footer img {
    padding: 0;
  }

  .black-bg {
    background-color: var(--black);
  }

  .contr {
    max-width: var(--container-width);
    margin: 0 auto;
  }

  .text-center {
    text-align: center;
  }
  .center {
    margin: 0 auto;
  }

  .margin-for-testing {
    margin-top: 200px;
  }

  .ft-mk .ft-logo {
    max-width: 340px;
  }

  .ft-mk p {
    font-size: 17px;
  }

  .img-resp {
    width: 100%;
    height: auto;
    display: block;
  }

  .footer-padding {
    padding: 20px 20px 20px 20px;
  }

  .emulated-flex-gap p {
    font-size: 17px;
  }

  .emulated-flex-gap {
    --gap: 20px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
    margin-top: 10px;
  }

  .emulated-flex-gap div {
    flex-basis: calc(33% - var(--gap));
  }

  .emulated-flex-gap > * {
    margin: var(--gap) 0 0 var(--gap);
  }

  @media only screen and (max-width: 625px) {
    .ft-mk {
      justify-content: flex-end;
    }

    .ft-mk .b2 {
      flex-basis: calc(50% - var(--gap)) !important;
    }

    .ft-mk .c1 {
      align-self: flex-end !important;

      flex-basis: 100% !important;
    }
  }

  @media only screen and (max-width: 500px) {
    .ft-mk .b2 {
      flex-basis: 100% !important;
    }

    .ft-mk .c1 {
      flex-basis: 100% !important;
    }
  }

  @media only screen and (max-width: 400px) {
    .footer-notice :not(:first-child):not(:last-child) {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .footer-notice {
    display: flex;
    flex-wrap: wrap-reverse;
    /* padding: 20px 20px; */
    justify-content: space-between;
    color: rgb(82, 82, 82);
    font-size: 14px;
  }

  .footer-notice-add {
    padding: 10px 20px;
  }

  .footer-notice span {
    margin-right: 30px;
    margin: 20px;
  }

  .footer-notice span a {
    color: rgb(81 106 99);
    font-weight: 400;
  }

  .line-ft {
    /* margin: 0 20px;  */
    border-top: 1px solid rgb(126 203 186);
  }

  .light-footer {
    padding-top: 10px;
    background-color: var(--lightgray);
  }

  .dark-footer {
    background-color: var(--black);
  }

  .light-footer .white-logo {
    display: none;
  }

  .dark-footer .black-logo {
    display: none;
  }

  .dark-footer p {
    color: #e9e9e9;
  }

  .dark-footer .footer-notice {
    color: #bebebe;
  }

  .dark-footer .footer-notice a {
    color: #bebebe;
  }

  .dark-footer .line-ft {
    border: none;
    border-top: 1px solid rgb(115, 115, 115);
  }
  /*------------------------------------
     END $FOOTER
  ------------------------------------*/