  /*------------------------------------
    $GENERAL
  ------------------------------------*/
:root {
  --container-width: 66rem;
  --white: #fff;
  --lightGray: #f4f4f8;
  --snowWhite: #fefefe;
  --green: #006767;
  /* --accent: (var(brightRed)); */
}

* {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--lightGray);
  margin: 0;
  padding: 0;
}

.gray-bg {
  background-color: var(--lightGray);
}
.green-bg {
  background-color: var(--green);
}
.white-bg {
  background-color: var(--white);
}
.round {
  border-radius: 15px;
}

.text-white {
  color: var(--white);
}

* {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  /* color: rgb(47 47 47); */
  box-sizing: border-box;
}

p {
  font-size: 1rem;
}

  /*------------------------------------
    $LAYOUT
    Break Points: 750px	970px	1170px
  ------------------------------------*/

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 1rem;
}
@media only screen and (max-width: 900px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .no-pad {
    padding: 0px;
  }
}

.width-400 {
  max-width: 400px;
}
.width-500 {
  max-width: 500px;
}

.flex {
  display: flex;
}
.split {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.basis30 {
  flex-basis: 30%;
  max-width: 500px;
  margin: 0 auto;
}

.basis40 {
  flex-basis: 40%;
  max-width: 500px;
  margin: 0 auto;
}
.basis50 {
  flex-basis: 50%;
  max-width: 500px;
  margin: 0 auto;
}
.basis60 {
  flex-basis: 60%;
  max-width: 500px;
  margin: 0 auto;
}
.basis70 {
  flex-basis: 70%;
  max-width: 500px;
  margin: 0 auto;
}

.px-5 {
  padding-left: 15px;
  padding-right: 15px;
}
.py-5 {
  padding-top: 50px;
}

.pb-5 {
  padding-bottom: 50px;
}
.pb-2 {
  padding-bottom: 20px;
}
.pt-5 {
  padding-top: 50px;
}
.pl-1 {
  padding-left: 10px;
}
.mx-1{
  margin-left: 10px;
  margin-right: 10px;
}
.green-bg {
  background-color: var(--green);
}

@media only screen and (max-width: 800px) {
  .split {
    flex-direction: column;
  }
  .basis50 {
    flex-basis: 100%;
  }
  .m-width-300 {
    max-width: 300px;
  }
  .m-width-200 {
    max-width: 200px;
  }
}

.py-5 {
  padding: 30px 0;
}

.icons img {
  max-width: 100px;
  margin: 0 auto;
}

  /*------------------------------------
    $TYPOGRAPHY
  ------------------------------------*/

* ul,
ol {
  /* display: block; */
  margin-block-start: 0em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 50px;
}

h1 {
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-weight: 700;
  line-height: 1.35;
  font-size: 2rem;
  padding-top: 20px;
}
h2 {
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 25px;
  line-height: 1.35;
  font-weight: 800;
  padding-bottom: 10px;
  padding-top: 30px;
}

p,
li {
  font-size: 1rem;
  line-height: 1.5;
}
li {
  margin-left: 30px;
}

p {
  margin-bottom: 20px;
}

blockquote {
  text-indent: -0.45em;
  border-left: 5px solid red;
  display: block;
}

blockquote::before {
  content: open-quote;
}
blockquote::after {
  content: close-quote;
}
blockquote {
  quotes: "“" "”" "‘" "’";
}

strong {
  font-weight: 600;
}
.text-white {
  color: var(--white);
}
.text-green {
  color: var(--green);
}
.text-red {
  color: var(--darkRed);
}
.text-center {
  text-align: center;
}

  /*------------------------------------
         $BUTTONS
    ------------------------------------*/
.btn,.btn:link,
.btn:visited {
  text-decoration: none;
  padding: 0.8em 2em;
  display: inline-block;
}

.btn-green {
  color: #006767;
  background-color: #cdf4e7;
  font-weight: 600;
}
.btn-green:hover {
  background-color: #c2ecde;
}
