  /*------------------------------------
    $HEADER
  ------------------------------------*/
.header {
  padding: 25px 20px;
  display: flex;
  justify-content: space-between;
}
.header img {
  width: 200px;
  max-width: 100%;
  height: auto;
}
.lead {
  padding: 60px 0px;
}

/* xxx  */
@media (max-width <= 800px) {
  #header .head-logo {
    flex-basis: 60%;
    margin: 20px;
  }
  #header .head-button {
    flex-basis: 40%;
    margin: 20px;
    display: flex;
    justify-content: flex-end;
  }
  #header .head-button .cta-b {
    display: flex;
    vertical-align: middle;
    padding: 0.5em 1em;
  }
  #header img {
    max-width: 200px;
  }
  .header {
    padding: 1px 20px;
    display: flex;
    justify-content: space-between;
  }
}

#header .head-logo {
  max-width: 70px;
}

.gradient {
  z-index: -100;
  width: 100%;
  position: fixed;
  height: 40px;
  background: linear-gradient(180deg, #e4eff1 -10%, transparent 100%);
}

#subscribe {
  background-image: url("../asset/img/hero-top.svg"),
  url("../asset/img/hero-bottom.svg");
  background-repeat: no-repeat,
      no-repeat;
  background-position: top left,
      bottom right;
background-size: 100% auto,
     100% auto;
}