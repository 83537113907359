#hero {
  background-image: url("../asset/img/hero-top.svg"),
  url("../asset/img/hero-bottom.svg");
  background-repeat: no-repeat,
      no-repeat;
  background-position: top left,
      bottom right;
background-size: 100% auto,
     100% auto;
  min-height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1rem;
  margin-bottom: 50px;
  transition: all 0.7s ease-in-out;
}

#hero:hover{
  background-size: 102% auto,
  102% auto;
transition: all 0.2s ease-in-out;
}

#hero h1,.h1 {
  font-size: 2.5rem;
  font-weight: 900;
  text-shadow: 5px 8px 20px rgba(0, 23, 47, 0.25),
    2px 2px 0px rgba(255, 255, 255, 0.84),
    1px 2px 0px rgba(255, 255, 255, 0.84),
    0px 2px 0px rgba(255, 255, 255, 0.84),
    -1px 2px 0px rgba(255, 255, 255, 0.84),
    -1px -1px 0px rgba(255, 255, 255, 0.84),
    -2px -1px 0px rgba(255, 255, 255, 0.84),
    -2px -2px 0px rgba(255, 255, 255, 0.84);
}
.rounded-home {
  border-radius: 20px;
}
.rounded-btn {
  border-radius: 50px;
}
.subscribe-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 750px;
  padding: 2rem 1rem;
  padding: 30px 20px;
  margin: 0px auto;
  flex-wrap: wrap;
  margin-top:-60px;
}
.subscribe-home h2 {
  color: var(--snowWhite);
  line-height: 1.5;
  max-width: 400px;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
}
.subscribe-home span {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  display: block;
}
.subscribe-home .asterix {
  font-size: 18px;
  display: inline;
  top: -10px;
  position: relative;
}
.pretty-white-box {
  /* background-color: rgba(255, 255, 255, 0.956);
  background-image: url("../asset/img/hero.svg"); */
  background-size: cover;
  height: 100%;
  border-radius: 15px;
  padding: 0px;
  margin: 20px 0px;
  flex-shrink: 0;
}

.pretty-white-box .btn {
  /* width: 200px; */
  width: auto;
}
